<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="我的推广" />
    <div class="page-content">
      <div class="page-header">
        <div class="count-item"><span>{{ count }}</span>人</div>
        <div class="name">当前粉丝</div>
      </div>
      <div class="title-item">粉丝列表</div>
      <van-list v-model="loading" :finished="finish" @load="handleGetPeopleList" loading-text="加载中..." finished-text="" error-text="加载失败">
        <div v-if="finish && !loading && peopleList.length == 0" class="empty-item">
          <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
        </div>
        <div v-else class="user-list">
          <div class="user-item" v-for="(item, index) in peopleList" :key="index">
            <div class="header img-cover">
              <img v-if="item.Header" :src="item.Header" />
              <img v-else src="../../assets/images/default-header.png" />
            </div>
            <div class="name">{{ item.Nickname }}</div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "People",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      PageCount: 10,
      Page: 1,
      loading: false,
      finish: false,
      peopleList: [],
      count: 0,
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {

  },
  methods: {
    async handleGetPeopleList() {
      const { PageCount, Page, peopleList } = this
      const params = {
        PageCount,
        Page,
        Type: 1
      }
      var res = await this.$API.peopleList.post(params)
      this.peopleList = [
        ...peopleList,
        ...res.info
      ]
      if (Page == 1) {
        this.count = res.count
      }
      this.Page = Page + 1
      this.finish = res.info.length < PageCount
      this.loading = false
    },
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
}
.page-content {
  padding: .24rem .32rem;
  .page-header {
    width: 100%;
    height: 1.84rem;
    border-radius: .16rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: .24rem;
    .count-item {
      color: #242941;
      span {
        margin-right: .08rem;
        font-size: .8rem;
        font-weight: bold;
      }
    }
    .name {
      line-height: .34rem;
      margin-top: .04rem;
      color: #c8cacc;
    }
  }
  .title-item {
    padding: .24rem 0 .2rem;
    line-height: .48rem;
    color: #242941;
    font-size: .32rem;
    font-weight: bold;
  }
  .user-list {
    background-color: #fff;
    border-radius: .16rem;
    padding: 0 .24rem;
    .user-item {
      height: 1.44rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: .02rem solid #f8f8f8;
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      .header {
        width: .96rem;
        height: .96rem;
        border-radius: 50%;
        overflow: hidden;
      }
      .name {
        flex: 1;
        margin-left: .32rem;
        color: #242941;
        font-size: .28rem;
      }
    }
  }
}
</style>